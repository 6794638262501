import SessionsService from '@/services/whatsapp/sessions.service.js'
import BrowserCache from '@/services/browser-cache.js'
import getSymbolFromCurrency from 'currency-symbol-map'

export default {
  name: 'SpentInbox',
  components: { },
  data () {
    return {
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      sessions: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
    }
  },
  computed: {
    params (nv) {
      return {
        ...this.options,
      }
    },
    headers () {
      return [
        {
          text: this.$t('Fecha'),
          value: 'created_at',
        },
        {
          text: this.$t('Conversaciones'),
          value: 'quantity',
        },
        {
          text: this.$t('Coste'),
          value: 'cost',
        },
        {
          text: this.$t('Balance'),
          value: 'balance',
        },

      ]
    },
  },
  watch: {
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
  },
  created () {},
  mounted () {
  },
  methods: {
    setParamsAndGetData () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
        ? 'asc'
        : 'desc'
      }
      this.getCurrentUserSessionTotal(params)
    },
    getCurrentUserSessionTotal (params) {
      this.disabledPagination = true
      SessionsService.getCurrentUserSessionTotal(params).then((sessions) => {
        this.sessions = sessions.data
        this.itemsPerPage = parseInt(sessions.per_page)
        this.page = sessions.current_page
        this.total = sessions.total

        this.disabledPagination = false
      })
    },
  },
}
